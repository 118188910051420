import request from "../../shared/request";
import showToast from "../../shared/showToast";
import { setBanners, setBestSelling, setHeaderRibbon, setHotSelling, setNewLaunching, setSaleBanner } from "./Home.actions";

export const getBestSellingProducts = (alreadyExists, bestSellersUpdatedAt) => async dispatch => {
  try {
    const res = await request("/product/best-selling", "get", false, null, { updatedAt: bestSellersUpdatedAt });
    const { products, updatedAt } = res.data.data;
    if (products.length) {
      dispatch(setBestSelling({ products, updatedAt }));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    if (!alreadyExists) showToast("Error", "Unable to get best selling products", true);
  }
};

export const getHotSellingProducts = (alreadyExists) => async dispatch => {
  try {
    const res = await request("/product/hot-sale", "get", false, null);
    console.log('api data: ', res)
    const { products } = res.data.data;
    if (products.length) {
      dispatch(setHotSelling({ products }));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    if (!alreadyExists) showToast("Error", "Unable to get best selling products", true);
  }
};

export const getNewLaunchingProducts = (alreadyExists) => async dispatch => {
  try {
    const res = await request("/product/new-launching", "get", false, null);
    console.log('api data: ', res)
    const { products } = res.data.data;
    dispatch(setNewLaunching({ products }));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    if (!alreadyExists) showToast("Error", "Unable to get best selling products", true);
  }
};

export const getHeaderRibbonData = (alreadyExists) => async dispatch => {
  try {
    const res = await request("/product/header-ribbon", "get", false, null);
    const data = res?.data?.data?.content;
    if (data) {
      dispatch(setHeaderRibbon(data));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    if (!alreadyExists) showToast("Error", "Unable to get header ribbon data", true);
  }
};

export const getBanners = (alreadyExists) => async dispatch => {
  try {
    const res = await request("/product/banners", "get", true);
    const data = res?.data?.data;
    if (data) {
      dispatch(setBanners(data));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    if (!alreadyExists) showToast("Error", "Unable to get header ribbon data", true);
  }
};
export const getSaleBannerData = (alreadyExists) => async dispatch => {
  try {
    const res = await request("/product/sale-banner", "get", false, null);
    const data = res?.data?.data;
    if (data) {
      dispatch(setSaleBanner(data));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    if (!alreadyExists) showToast("Error", "Unable to get header ribbon data", true);
  }
};
