import React, { useState, lazy, Suspense, useRef, useEffect } from "react";
import stylesheet from "./Navbar.styles";
import { Link, withRouter, useHistory, useLocation } from "react-router-dom";
import cart from "../../assets/Navbar/shopping_cart-24px.svg";
import cartMobile from "../../assets/Navbar/shopping-cart-mobile.svg";
import { ReactComponent as LogoutSvg } from "../../assets/Navbar/logout.svg";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Hamburger from "hamburger-react";
import { connect, useDispatch, useSelector } from "react-redux";
import { logout } from "../AuthModal/Auth.service";
import { bindActionCreators } from "redux";
import { Popover } from "@material-ui/core";
import logo from "../../assets/Navbar/logo.svg";
import { ReactComponent as UserSvg } from "../../assets/Navbar/user.svg";
import { ReactComponent as ArrowDown } from "../../assets/Navbar/arrow-down.svg";
import { ReactComponent as SearchIcon } from "../../assets/Navbar/search.svg";
import BottomMenusMobile from "../BottomMenusMobile";
import EditorContent from "../../components/EditorContent/EditorContent";
import { getHeaderRibbonData } from "../../containers/HomePage/Home.service";
import { searchEvent } from "../../utils/facebookPixelEvents";
const CartDropdown = lazy(() => import("./CartDropdown"));
const NavbarDrawer = lazy(() => import("./NavbarDrawer"));
const AuthModal = lazy(() => import("../AuthModal"));

const HamburgerMenu = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M3 7H21" stroke="#292D32" stroke-width="1.5" stroke-linecap="round"/>
  <path d="M3 12H21" stroke="#292D32" stroke-width="1.5" stroke-linecap="round"/>
  <path d="M3 17H21" stroke="#292D32" stroke-width="1.5" stroke-linecap="round"/>
</svg>
);

const serviceOptions = [
  {
    id: 0,
    text: "Interactive Home",
    route: "/interactive-home",
  },
  {
    id: 1,
    text: "Mood Controlled Lighting",
    route: "/mood-controlled-lighting",
  },
  // {
  //   id: 2,
  //   text: "Water & Energy Management",
  //   route: "/water-energy-management",
  // },
  // {
  //   id: 3,
  //   text: "Keyless Entry",
  //   route: "/keyless-entry",
  // },
  // {
  //   id: 4,
  //   text: "Security & Surveillance",
  //   route: "/security-and-surveillance",
  // },
  // {
  //   id: 5,
  //   text: "Automatic Gates",
  //   route: "/automatic-gates",
  // },
  // {
  //   id: 6,
  //   text: "Solar Energy",
  //   route: "/solar-energy",
  // },
  // {
  //   id: 7,
  //   text: "Other Products",
  //   route: "/other-products",
  // },
];

const Navbar = ({ location, user, logout, cartItemsCount }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
  const [cartAnchorEl, setCartAnchorEl] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [loginPopupOpen, setLoginPopupOpen] = useState(false);
  
  const dispatch = useDispatch();
  const { headerRibbon } = useSelector(state => state.home);

  useEffect(() => {
    dispatch(getHeaderRibbonData(headerRibbon));
  }, []);

  const history = useHistory();
  const {pathname} = useLocation();

  const classes = stylesheet();

  const userMenuOptions = [
    {
      id: 0,
      text: "My Orders",
      route: "/orders/p",
    },
    {
      id: 1,
      text: "My Reviews",
      route: "/reviews/tr",
    },
    {
      id: 2,
      text: "My Favourites",
      route: "/favourites",
    },
    {
      id: 3,
      text: "My Account",
      route: "/account",
    },
    {
      id: 4,
      text: (
        <>
          <LogoutSvg style={{ marginRight: 6 }} /> Sign Out
        </>
      ),
      onClick: logout,
    },
  ];

  const headerRef = useRef(null);
  const loginIconRef = useRef(null);
  
  const handlePageScroll = () => {
    const sticky = headerRef?.current?.offsetTop;
    if(headerRef) {
      if (window.pageYOffset > sticky) {
        headerRef.current.classList.add("sticky-header");
      } else {
        headerRef.current.classList.remove("sticky-header");
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handlePageScroll);
  
    return () => {
      window.removeEventListener('scroll', handlePageScroll);
    }
  }, []);

  const handleCloseAuthModal = () => {
    setShowAuthModal(false);
  };

  const navHandler = () => {
    window.scrollTo(0, 0);
    setOpen(false);
  };

  const userClickHandler = (e) => {
    e.preventDefault();
    if (user.name) {
      setUserMenuAnchorEl(e.currentTarget);
    } else {
      setShowAuthModal(true);
    }
  };

  const handleSearchInput = (e) => {
    setSearchValue(e.target.value);
  }

  const searchHandler = (e) => {
    if(!searchValue) return;
    if(e.key === 'Enter') {
      searchEvent({query: e.target.value});
      history.push('/shop/6gkSICnXAzNBlQWPjPuZ');
    }
  }

  const renderCartPopover = () =>
    <Suspense fallback={<div />}>
      <CartDropdown
        cartAnchorEl={cartAnchorEl}
        setCartAnchorEl={setCartAnchorEl}
        classes={classes}
        cartItemsCount={cartItemsCount} />
    </Suspense>;

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      {showAuthModal
        ? <Suspense fallback={<div />}>
          <AuthModal handleClose={handleCloseAuthModal} />
        </Suspense>
        : null}
      <div className="top-bar">
        <div className="top-bar__content">
          <div>
            <EditorContent content={headerRibbon} style={{whiteSpace: 'initial'}} noBg />
          </div>
        </div>
      </div>
      <div className="bottom-bar" ref={headerRef}>
        <div className="bottom-bar__content">
          <Suspense fallback={<div />}>
            <NavbarDrawer
              isOpen={isOpen}
              setOpen={setOpen}
              serviceOptions={serviceOptions}
              navHandler={navHandler}
              classes={classes} />
          </Suspense>

          <div className="desktop-header">
            <div className="logo-container">
              <Link to="/" onClick={navHandler}>
                <img src={logo} alt="" />
              </Link>
            </div>

            <div className="nav-items-container">
              <div className={`nav-item${pathname === '/' ? ' selected' : ''}`}>
                <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                  Home
                </Link>
              </div>
              <div
                className={`nav-item ${open || pathname === '/interactive-home' || pathname === '/mood-controlled-lighting' ? "selected" : ""}`}
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <span>Services</span>
                <ArrowDown
                  style={{
                    marginLeft: "4px",
                  }}
                />
              </div>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                className={classes.popover}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <div className="service-options-container">
                  {serviceOptions.map((o) => (
                    <div
                      key={o.id}
                      className={`service-option ${o.route === location.pathname
                        ? "service-option-selected"
                        : ""
                        }`}
                    >
                      <Link to={o.route} onClick={navHandler}>
                        {o.text}
                      </Link>
                    </div>
                  ))}
                </div>
              </Popover>
              <div className="nav-item">
                <Link className="nav-item" to="/" onClick={() => window.scrollTo(0, 0)}>
                  Blogs
                </Link>
              </div>
              <div className={`nav-item${pathname === '/contact-us' ? ' selected' : ''}`}>
                <Link className={`nav-item${pathname === '/contact-us' ? ' selected' : ''}`} to="/contact-us" onClick={() => window.scrollTo(0, 0)}>
                  Contact Us
                </Link>
              </div>
            </div>

            <div className="header-left-container">
              <div class="header-input-wrapper">
                <div class="header-input-icon"><SearchIcon /></div>
                {!searchValue && <div class="header-input-placeholder">Search <span>"Smart Plug"</span></div>}
                <input class="header-input" value={searchValue} onChange={handleSearchInput} onKeyUp={searchHandler} />
              </div>  
              {user.name ? (
                <>
                  <a
                    href="/"
                    onClick={userClickHandler}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <UserSvg />
                  </a>
                  <Popover
                    id="user-menu"
                    open={Boolean(userMenuAnchorEl)}
                    anchorEl={userMenuAnchorEl}
                    className={classes.userMenuPopover}
                    onClose={() => setUserMenuAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <div className="user-menu-option-container">
                      {userMenuOptions.map((o) => (
                        <div
                          key={o.id}
                          className="user-menu-option-text"
                          onClick={() => {
                            window.scrollTo(0, 0);
                            if (o.onClick) o.onClick();
                          }}
                        >
                          <Link to={o.route || location.pathname}>{o.text}</Link>
                        </div>
                      ))}
                    </div>
                  </Popover>
                </>
              ) : (
                <>
                <a href="/" ref={loginIconRef} onClick={(e) => {e.preventDefault(); setLoginPopupOpen(true);}} style={{height: '24px'}}>
                  <UserSvg />
                </a>
                <Popover
                id="user-menu"
                open={loginPopupOpen}
                anchorEl={loginIconRef.current}
                className={classes.loginPopover}
                classes={{ paper: classes.loginPopoverRoot }}
                onClose={() => setLoginPopupOpen(false)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <div style={{background: '#fff', borderRadius: '12px', marginTop: '14px', border: '1px solid #E6EAEE', width: '220px', height: '96px', padding: '12px',  display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}} className="user-menu-option-container">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                      <div style={{color: '#292D32', fontSize: '14px', fontWeight: '500',}}>Hi Pintar Fam!</div>
                      <div onClick={() => {setLoginPopupOpen(false)}} style={{lineHeight: '0', cursor: 'pointer'}}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M7.75732 16.2426L16.2426 7.75736" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16.2426 16.2426L7.75732 7.75736" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      </div>
                    </div>
                    <div
                      className="user-menu-option-text"
                      onClick={() => {
                        setLoginPopupOpen(false);
                        setShowAuthModal(true);
                      }}
                      style={{
                        height: '40px',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#fff',
                        borderRadius: '16px',
                        background: '#D5AC4F',
                        fontSize: '14px',
                        cursor: 'pointer'
                      }}
                    >
                      Login
                    </div>
                </div>
              </Popover>
              </>
              )}
              <div className="cart-container" onClick={(e) => setCartAnchorEl(e.currentTarget)}>
                <img src={cart} alt="" />
                {cartItemsCount > 0 && <div className="cart-items-count">{cartItemsCount}</div>}
              </div>
            </div>
          </div>

          <div className="mobile-header">
              <div className="hamburger-n-logo-container" style={{display: "flex", alignItems: "center", gap: "12px"}}>
                <div className="hamburger-container" style={{margin: "0px", lineHeight: 0}} onClick={() => {
                  setOpen(!isOpen)
                }}>
                  <HamburgerMenu />
                </div>
                <div className="logo-container">
                  <Link to="/" onClick={navHandler}>
                    <img src={logo} alt="" height="40" />
                  </Link>
                </div>
              </div>
              <div className="user-n-cart-container" style={{display: 'flex', alignItems: "center", gap: "14px"}}>
                {/* <SearchIcon onClick={userClickHandler} /> */}
                <SearchIcon onClick={() => {searchEvent({query: 'mobile search'})}} />
                <div className="cart-container" onClick={(e) => setCartAnchorEl(e.currentTarget)}>
                  <img src={cart} alt="" />
                  {cartItemsCount > 0 && <div className="cart-items-count">{cartItemsCount}</div>}
                </div>
              </div>
          </div>



          {renderCartPopover()}
        </div>
      </div>
      {<BottomMenusMobile userClickHandler={userClickHandler} username={user.name} setAnchorEl={setAnchorEl} />}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  cartItemsCount: state.cart.cartItems.length
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));
