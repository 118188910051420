import React from 'react';
import draftToHtml from 'draftjs-to-html';
import classes from './EditorContent.module.css';

const EditorContent = ({ content, noBg, style }) => {
  if (!content) return <div />;

  let bgClass = classes['bg-class'];
  if (noBg) {
    bgClass = "";
  }

  return <div
    className={`${classes['editor-container']} ${bgClass}`}
    style={{...(style)}}
    dangerouslySetInnerHTML={{ __html: draftToHtml(JSON.parse(content)) }} />;
};

export default EditorContent;