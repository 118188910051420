import actionTypes from './Home.types';

export const setBestSelling = (payload) => ({
  type: actionTypes.SET_BEST_SELLING,
  payload
})

export const setHotSelling = (payload) => ({
  type: actionTypes.SET_HOT_SELLING,
  payload
})
export const setNewLaunching = (payload) => ({
  type: actionTypes.SET_NEW_LAUNCHING,
  payload
})
export const setHeaderRibbon = (payload) => ({
  type: actionTypes.SET_HEADER_RIBBON,
  payload
})
export const setSaleBanner = (payload) => ({
  type: actionTypes.SET_SALE_BANNER,
  payload
})
export const setBanners = (payload) => ({
  type: actionTypes.SET_BANNERS,
  payload
})