export default {
  white: '#FFF',
  black: '#000',
  darkGrey1: '#36373A',
  darkGrey4: '#f6f6f6',
  darkGrey7: "#262626",
  darkGrey8: "#686A70",
  lightGrey2: '#c8c9c9',
  lightGrey3: "#E4E4E4",
  lightGrey9: "#00000024",
  darkYellow1: '#D5AC4F',
  darkYellow2: " #d3ac4f",
  lightYellow1: '#E5BC5A',
  lightPink: '#D5AC4F34',
  slightlyWhite: 'rgba(255,255,255,0.3)'
}