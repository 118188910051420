import { makeStyles } from "@material-ui/core/styles";
import colors from "../../shared/colors";

const useStyles = makeStyles({
  popover: {
    "& .service-options-container": {
      "& .service-option": {
        cursor: "pointer",
        color: colors.darkGrey1,
        borderBottom: `1px solid ${colors.darkYellow1}`,
        userSelect: "none",
        fontSize: "15px",
        transition: "0.1s ease-out",
        "&:hover": {
          color: colors.white,
          background: colors.darkYellow1,
        },
        "& a": {
          textDecoration: "none",
          display: "inline-block",
          width: "100%",
          padding: "5px 12px",
          color: "inherit",
        },
      },
      "& .service-option-selected": {
        color: colors.white,
        background: colors.darkYellow1,
      },
    },
    "& .drawer-logo-container": {
      height: "10rem",
      padding: "5px 12px 10px",
      borderBottom: `1px solid ${colors.darkYellow1}`,
      "& img": {
        height: "100%",
      },
    },
  },
  userMenuPopover: {
    "& .user-menu-option-container": {
      padding: "4px 0",
      "& .user-menu-option-text": {
        cursor: "pointer",
        color: colors.darkGrey1,
        userSelect: "none",
        fontWeight: "500",
        fontSize: "1.2rem",
        "& a": {
          textDecoration: "none",
          display: "flex",
          alignItems: "center",
          width: "100%",
          padding: "4px 12px",
          color: "inherit",
        },
        "&:hover": {
          color: colors.darkYellow2,
          "& .a": {
            fill: colors.darkYellow2,
          },
        },
      },
    },
  },
  cartPopoverRoot: {
    overflow: "visible !important",
    marginTop: "1.3rem !important",
    "@media(max-width:768px)": {
      marginTop: "1rem !important",
    },
  },
  cartPopover: {
    overflow: "visible",
    "& .cart-dropdown": {
      background: colors.white,
      width: "30rem",
      borderTop: `4px solid ${colors.darkYellow2}`,
      borderRadius: "3px",
      padding: "0.5rem 1.6rem",
      boxShadow: "1px 1px 6px #00000024",
    },
    "& .cart-dropdown-empty-container": {
      padding: "1rem 1.4rem",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
    },

    "& .cart-dropdown-empty-text": {
      font: "500 1.2rem/1.8rem Poppins",
      letterSpacing: "0px",
      color: "#36373A",
      textAlign: "center",
      maxWidth: "13rem",
    },
    "& .cart-dropdown__top-triangle": {
      clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)",
      "-webkit-clip-path": "polygon(50% 0%, 0% 100%, 100% 100%)",
      backgroundColor: `${colors.darkYellow2}`,
      width: "1.2rem",
      height: "0.9rem",
      position: "absolute",
      top: "-0.9rem",
      right: "0.8rem",
      "@media(max-width:768px)": {
        top: -9,
        right: 9,
        width: 12,
        height: 9,
      },
    },
    "& .cart-items": {
      display: "block",
      maxHeight: "calc(100vh - 43rem)",
      overflowY: "auto",
      margin: "10px 0",
      padding: "0 5px",

      "&::-webkit-scrollbar": {
        width: "3px",
        display: "inherit",
        "-webkit-appearance": "none",
        "-webkit-overflow-scrolling": "touch",
      },
      /* Track */
      "&::-webkit-scrollbar-track": {
        background: "#ccc",
      },
      /* Handle */
      "&::-webkit-scrollbar-thumb": {
        background: colors.darkYellow1,
      },
    },
    "& .cart-item": {
      display: "flex",

      // padding: 2rem 0rem;
      alignItems: "center",
      padding: "1.6rem 0",

      "&:not(:last-child)": {
        borderBottom: "1px solid #e4e4e4",
      },
    },

    "& .cart-item-details-toggle": {
      font: "600 1.2rem/1.8rem Poppins",
      marginBottom: "6px",
      color: "#36373A",
      cursor: "pointer",
      textTransform: "uppercase",
      display: "flex",
      alignItems: "center",
    },
    "& .cart-item-left": {
      display: "flex",
      alignSelf: "flex-start",
      // flex: 3;
    },

    "& .cart-item-right": {
      flex: "1",
    },

    "& .cart-item-info": {
      alignSelf: "flex-start",
    },

    "& .cart-item-heading": {
      font: "600 1.4rem/2.1rem Poppins",
      letterSpacing: "0px",
      color: "#36373a",
      opacity: 1,
      marginBottom: "4px",
    },
    "& .cart-item-heading-container": {
      display: "flex",
      justifyContent: "space-between",
    },
    "& .cart-item-color": {
      font: "700 1.2rem/1.8rem Poppins",
      letterSpacing: "0px",
      color: "#686a70",
      display: "flex",
      alignItems: "center",

      "& .name": {
        fontWeight: "400",
        marginRight: "6px",
        marginLeft: "3px",
        textTransform: "capitalize",
      },

      "& .dot": {
        width: "1.2rem",
        height: "1.2rem",
        borderRadius: "100%",
        backgroundColor: "#000",
        border: "1px solid #ccc",
      },
    },

    "& .cart-item-desc": {
      transition: "height 0.2s ease-in-out",
      overflow: "hidden",
    },
    "& .cart-item-type": {
      font: "700 1.2rem/1.8rem Poppins",
      letterSpacing: "0px",
      color: "#686a70",
      marginBottom: "8px",

      "& span": {
        fontWeight: "400",
      },
    },
    "& .cart-item-price": {
      flex: 1,
      font: "400 1.4rem/2.1rem Poppins",
      letterSpacing: "0px",
      color: "#36373a",
      opacity: 1,
    },
    "& .cart-item-image": {
      borderRadius: "1.2rem",
      border: "0.2rem solid #e4e4e4",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
      cursor: "pointer",
      // maxWidth: "6.5rem",
      transition: "0.2s ease-in-out",
      marginRight: "2.4rem",
      "& img": {
        width: "5rem",
        height: "5rem",
      },
      "&:hover": {
        border: "0.2rem solid #d5ac4f",
      },
    },
    "& .cart-item-subtotal": {
      // flex: 1;

      font: "700 1.4rem/2.1rem Poppins",
      letterSpacing: "0px",
      color: "#36373a",
      opacity: 1,
    },
    "& .cart-item-cross": {
      width: "1.6rem",
      height: "1.6rem",
      cursor: "pointer",
      backgroundColor: "#fff",
      boxShadow: "0px 1px 2px #00000029",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "100%",

      "& img": {
        height: "1rem",
        width: "1rem",
      },
    },
    "& .cart-item-quantity-box": {
      display: "flex",
    },
    "& .product__row-counter": {
      backgroundColor: colors.white,
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      width: "8rem",
      borderBottomLeftRadius: "4px",
      borderTopLeftRadius: "4px",
      height: "3rem",
      border: "1px solid #e4e4e4",
    },
    "& .product__row-counter-text": {
      font: "700 1.2rem/2.8rem Poppins",
      color: colors.darkGrey7,
      textAlign: "center",
    },

    "& .product__row-counter-btn": {
      // height: 100%;
      cursor: "pointer",
      width: "100%",
      height: "100%",

      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      userSelect: "none",
      "&:focus": {
        outline: "none",
      },
    },
    "& .product__row-price": {
      color: "#36373a",
      font: "600 1.2rem/1.8rem Poppins",
      backgroundColor: "#ededed",
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "8rem",
    },
    "& .cart-dropdown__content-top-section": {
      display: "flex",
      padding: "0.5rem 0",
      justifyContent: "space-between",
      borderBottom: `1px solid ${colors.lightGrey9}`,
    },

    "& .cart-dropdown__items-count": {
      color: colors.darkGrey1,
      fontWeight: 700,
      fontSize: "12px",
      font: "500 1.2rem/1.8rem Poppins",

      "& span": {
        color: colors.darkGrey8,
        fontWeight: 400,
      },
    },

    "& .cart-dropdown__cart-link": {
      color: colors.darkGrey1,
      font: "500 1.4rem/2.1rem Poppins",
    },

    "& .cart-dropdown__content-middle-section": {
      // height: 50px;
    },
    "& .cart-dropdown__content-bottom-section": {
      padding: "15px 0 10px",
    },
    "& .cart-dropdown__content-bottom-price-container": {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "10px",
      fontSize: "13px",
    },
    "& .summary-heading": {
      font: "500 2rem/3rem Poppins",
      letterSpacing: "0px",
      color: "#36373a",
      paddingBottom: "1.6rem",
    },
    "& .summary-details": {
      borderBottom: "1px solid #e4e4e4",
      borderTop: "1px solid #e4e4e4",
      padding: "1.6rem 0",
    },
    "& .summary-details-heading": {
      font: "600 1.6rem/2.5rem Poppins",
      letterSpacing: "0px",
      color: "#36373a",
      marginBottom: "1.6rem",
    },
    "& .summary-details-discount-heading": {
      font: "600 1.4rem/2.1rem Poppins",
      letterSpacing: "0px",
      color: "#36373a",
      marginBottom: "1.6rem",
    },
    "& .summary-details-apply": {
      display: "flex",
    },
    "& .summary-details-apply-input": {
      flex: 1,
    },
    "& .summary-details-row": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "&:not(:last-child)": {
        marginBottom: "1.2rem",
      },
    },
    "& .row-left": {
      font: "400 1.4rem/2.1rem Poppins",
      letterSpacing: "0px",
      color: "#686a70",

      "& .bold": {
        fontWeight: "600",
        color: "#36373a",
      },
    },
    "& .row-right": {
      font: "600 1.6rem/2.5rem Poppins",
      letterSpacing: "0px",
      color: "#36373a",
    },
  },
  drawer: {
    "& .heading": {
      color: colors.darkGrey1,
      fontSize: 20,
      fontWeight: 500,
      padding: "7px 16px",
      borderBottom: `1px solid ${colors.lightGrey3}`,
    },
    "& .drawer-root-option": {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      color: colors.darkGrey1,
      fontWeight: 500,
      padding: "7px 16px",
      "& svg": {
        transition: "transform 400ms linear 0s",
        marginLeft: "4px",
        fontSize: "20px",
      },
    },
    "& .service-options-container": {
      margin: "-4px 0 2px",
      overflow: "hidden",
      transition: "all 400ms linear 0s",
    },
    "& .drawer-level-1-option": {
      display: "block",
      textDecoration: "none",
      color: colors.darkGrey1,
      padding: "4px 16px 4px 24px",
    },
  },
  loginPopoverRoot: {
    background: 'transparent !important',
    boxShadow: 'none',
    // top: '112px !important',
    borderRadius: '12px'
  },
});

export default useStyles;
