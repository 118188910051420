import ReactPixel from 'react-facebook-pixel';

export const addToCartEvent = (data) => {
    ReactPixel.track('Pintar-AddToCart', data);
}

export const viewProductEvent = (data) => {
    ReactPixel.track('Pintar-ViewProduct', data);
}

export const purchaseSuccessEvent = (data) => {
    ReactPixel.track('Pintar-PurchaseSuccess', data);
}

export const purchaseFailEvent = (data) => {
    ReactPixel.track('Pintar-PurchaseFail', data);
}

export const searchEvent = (data) => {
    ReactPixel.track('Pintar-Search', data);
}

