import React, { useState } from "react";
import "./bottom-menu.scss";

import { Link } from "react-router-dom";
import {useHistory, useLocation} from "react-router-dom"

const MenuItem = (({title,Icon, userClickHandler, username, setAnchorEl}) => {
    const history = useHistory();
    const {pathname} = useLocation();
    const [activePage, setActivePage] = useState('Home');
    console.log({pathname})

    return (
      <div className={`bottom-menu${pathname === '/' && title === 'Home' ? ' active-tab' : ''}`} onClick={(e) => {
        if(title === 'Login') {
          userClickHandler(e);
          return;
        }
        if(title === 'Home') history.push('/');

        if(title === 'Categories') {
          setAnchorEl(e.currentTarget);
        }
        if(title === 'Chat') {
          window.open("https://wa.me/923000746827/");
        }
      }}>
        <div style={{lineHeight: 0}}>
          {Icon && <Icon />}
        </div>
        <div 
          style={{maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
        >
          {title === 'Login' && !!username ? username : title}
        </div>
      </div>
    );
  }
);

export default MenuItem;